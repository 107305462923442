import {createSlice} from '@reduxjs/toolkit'

export const userSlice = createSlice({
    'name': 'user',
    'initialState': {
        'value': {
            'filters': {},
            'layers': {}, // layers (value) by country (key)
            'modes': {}, // mode (value) by country (key)
            'selection': null,
            'substation': null,
        },
    },
    'reducers': {
        'setFilters': (state, action) => {
            state.value.filters = action.payload;
        },
        'setLayers': (state, action) => {
            state.value.layers = action.payload;
        },
        'setModes': (state, action) => {
            state.value.modes = action.payload;
        },
        'setSelection': (state, action) => {
            state.value.selection = action.payload;
        },
        'setSubstation': (state, action) => {
            state.value.substation = action.payload;
        },
    },
});

export const {setFilters, setLayers, setModes, setSelection, setSubstation} = userSlice.actions;

export default userSlice.reducer;
