import {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {setLayers as setUserLayers, setModes as setUserModes} from '../redux/userSlice';
import {Modal} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import {getAvailableModes, isObjectEmpty, setUserProfile} from '../utils';

export function Profile({ setShow, show }) {
    const dispatch = useDispatch();

    const country = useSelector(state => state.country.value.current);
    const layers = useSelector(state => state.layers.value);
    const userLayers = useSelector(state => state.user.value.layers);
    const userModes = useSelector(state => state.user.value.modes);

    const [availableLayers, setAvailableLayers] = useState([]);
    const [availableModes, setAvailableModes] = useState(getAvailableModes(country));

    const renderLayerCheckbox = layer => {
        return <Form.Check checked={layer.isActive === true} id={layer._id} key={layer._id} onChange={() => changeLayer(layer)} type="checkbox" label={layer.name} />;
    }

    const changeMode = event => {
        const newMode = event.target.value;
        const localUserModes = {...userModes};
        localUserModes[country] = newMode;

        dispatch(setUserModes(localUserModes));

        let localAvailableLayers = null;
        const newActiveLayers = availableModes?.[newMode]?.activeLayers?.[country];
        if (newActiveLayers !== undefined) {
            // update available layers on which the selectable list is based
            localAvailableLayers = [];
            availableLayers.forEach(availableLayer => {
                const isActive = newActiveLayers.includes(availableLayer.name);
                localAvailableLayers.push({
                    ...availableLayer,
                    'isActive': isActive,
                });
            });
            setAvailableLayers(localAvailableLayers);
        }

        save(localAvailableLayers, localUserModes);
    };

    const changeLayer = toggledLayer => {
        // update available layers on which the selectable list is based
        const localAvailableLayers = [];
        availableLayers.forEach(availableLayer => {
            const isActive = toggledLayer._id === availableLayer._id ? !availableLayer.isActive : availableLayer.isActive;
            localAvailableLayers.push({
               ...availableLayer,
               'isActive': isActive,
            });
        });
        setAvailableLayers(localAvailableLayers);

        console.log('changeLayer', userModes)
        save(localAvailableLayers, userModes);
    };

    // persist to local storage
    const save = (localAvailableLayers, localUserModes) => {
        const localUserLayers = {...userLayers};

        if (null !== localAvailableLayers) {
            localUserLayers[country] = {};
            localAvailableLayers.filter(availableLayer => availableLayer.isActive === true).forEach(availableLayer => {
                localUserLayers[country][availableLayer._id] = availableLayer.name;
            });
            dispatch(setUserLayers(localUserLayers));
        }

        setUserProfile({
            'layers': localUserLayers,
            'modes': localUserModes,
        });
    };

    // init available layers based on all layers and user's layers
    useEffect(() => {
        if (null !== layers.general && null !== layers.search && null !== userLayers) {
            const profileLayers = [];
            [...layers.general, ...layers.search].forEach(localLayer => {
                profileLayers.push({
                    ...localLayer,
                    'isActive': country in userLayers && Object.keys(userLayers[country]).includes(localLayer._id) === true,
                });
            });
            setAvailableLayers(profileLayers.sort((a, b) => a.name > b.name ? 1 : -1));
        }

        return () => {
            setAvailableLayers([]);
        }
    }, [layers]);

    useEffect(() => {
        setAvailableModes(getAvailableModes(country));
    }, [country]);

    return (
        <Modal backdrop={true} centered={true} onHide={() => setShow(false)} show={show}>
            <Modal.Header closeButton>
                <Modal.Title>Profile Settings</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {false === isObjectEmpty(availableModes) && <>
                    <h5>Mode</h5>
                    <Form.Select aria-label="Select your application mode" onChange={changeMode} value={userModes[country]}>
                        {Object.keys(availableModes).sort((a, b) => availableModes[a].label > availableModes[b].label ? 1 : -1).map(key => <option key={key} value={key}>{availableModes[key].label}</option>)}
                    </Form.Select>
                </>}
                <h5 className="mt-2">General layers</h5>
                <div className="ps-2">
                    {availableLayers.filter(availableLayer => country === availableLayer.country && 'general' === availableLayer.scope).map(availableLayer => renderLayerCheckbox(availableLayer))}
                </div>
                <h5 className="mt-2">Search layers</h5>
                <div className="ps-2">
                    {availableLayers.filter(availableLayer => country === availableLayer.country && 'general' !== availableLayer.scope).map(availableLayer => renderLayerCheckbox(availableLayer))}
                </div>
            </Modal.Body>
            <Modal.Footer />
        </Modal>
    );
}
